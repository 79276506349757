import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/eliminar-50.png'
import _imports_1 from '@/assets/Vector-servicio.png'


const _hoisted_1 = { class: "header-content" }
const _hoisted_2 = { style: {"text-align":"center","color":"white","font-weight":"100"} }
const _hoisted_3 = { class: "group-1" }
const _hoisted_4 = { class: "group" }
const _hoisted_5 = { class: "group" }
const _hoisted_6 = { class: "btn-content" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 0,
  style: {"margin":"20px 0px"}
}
const _hoisted_10 = {
  key: 0,
  class: "group-2"
}
const _hoisted_11 = { class: "group" }
const _hoisted_12 = { class: "group" }
const _hoisted_13 = { class: "group" }
const _hoisted_14 = { class: "group" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = { class: "group" }
const _hoisted_17 = ["value"]
const _hoisted_18 = { class: "group" }
const _hoisted_19 = { class: "group" }
const _hoisted_20 = { class: "group" }
const _hoisted_21 = ["value"]
const _hoisted_22 = { class: "btn-content" }
const _hoisted_23 = ["disabled"]
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { style: {"display":"flex","justify-content":"space-evenly","width":"90%","margin":"auto","align-items":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Map = _resolveComponent("Map")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, { class: "content" }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
          style: {"cursor":"pointer","margin-top":"-1rem"},
          width: "25",
          height: "25",
          src: _imports_0,
          alt: "delete-sign--v1"
        }),
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('message.planta.f1')), 1)
      ])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", {
          class: "popup-form",
          onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.address_selected ? _ctx.continuar() : _ctx.verificarAddress()), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              required: "",
              maxlength: "9",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.latitude) = $event)),
              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.limpia())),
              class: "custom-input",
              type: "text",
              placeholder: "Latitud",
              style: {"padding-left":"0.5rem"}
            }, null, 544), [
              [_vModelText, _ctx.latitude]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              maxlength: "9",
              required: "",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.longitude) = $event)),
              onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.limpia())),
              class: "custom-input",
              type: "text",
              placeholder: "Longitud",
              style: {"padding-left":"0.5rem"}
            }, null, 544), [
              [_vModelText, _ctx.longitude]
            ])
          ]),
          (_ctx.direcciones.length>0)
            ? (_openBlock(), _createBlock(_component_v_select, {
                key: 0,
                modelValue: _ctx.address_selected,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.address_selected) = $event)),
                class: "searche",
                label: "postal_code",
                placeholder: "Codigo Postal",
                options: _ctx.direcciones
              }, null, 8, ["modelValue", "options"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              disabled: _ctx.isLoading,
              type: "submit",
              id: "submit",
              class: "btn-submit-address",
              value: ""
            }, [
              (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                    key: 0,
                    style: {"width":"25px","height":"25px"},
                    strokeWidth: "8",
                    fill: "transparent",
                    animationDuration: ".5s",
                    "aria-label": "Loading"
                  }))
                : (_openBlock(), _createElementBlock("strong", _hoisted_8, _toDisplayString(_ctx.$t('message.planta.f3')), 1))
            ], 8, _hoisted_7)
          ])
        ], 32),
        (_ctx.validAddress)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_Map, {
                latitud: _ctx.address_selected.latitude,
                longitud: _ctx.address_selected.longitude,
                height: 200,
                width: 320
              }, null, 8, ["latitud", "longitud"])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.validAddress)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("form", {
              class: "popup-form",
              onSubmit: _cache[15] || (_cache[15] = _withModifiers(($event: any) => (_ctx.newServices(_ctx.newService)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_11, [
                _withDirectives(_createElementVNode("input", {
                  class: "custom-input",
                  type: "text",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.address_selected.country) = $event)),
                  disabled: "",
                  style: {"padding-left":"0.5rem"}
                }, null, 512), [
                  [_vModelText, _ctx.address_selected.country]
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _withDirectives(_createElementVNode("input", {
                  class: "custom-input",
                  type: "text",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.address_selected.country_code) = $event)),
                  disabled: "",
                  style: {"padding-left":"0.5rem"}
                }, null, 512), [
                  [_vModelText, _ctx.address_selected.country_code]
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _withDirectives(_createElementVNode("input", {
                  class: "custom-input",
                  type: "text",
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.address_selected.municipality) = $event)),
                  disabled: "",
                  style: {"padding-left":"0.5rem"}
                }, null, 512), [
                  [_vModelText, _ctx.address_selected.municipality]
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _withDirectives(_createElementVNode("input", {
                  class: "custom-input",
                  type: "text",
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.newService.sai) = $event)),
                  maxlength: "20",
                  required: "",
                  placeholder: _ctx.$t('message.planta.f5'),
                  pattern: "[A-Za-z0-9]+",
                  style: {"padding-left":"0.5rem"}
                }, null, 8, _hoisted_15), [
                  [_vModelText, _ctx.newService.sai]
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.newService.product_id) = $event)),
                  required: ""
                }, [
                  _cache[16] || (_cache[16] = _createElementVNode("option", { value: null }, "Seleccione un Plan", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planes, (plan) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: 'plan-'+plan.id,
                      value: plan.id
                    }, _toDisplayString(plan.name), 9, _hoisted_17))
                  }), 128))
                ], 512), [
                  [_vModelSelect, _ctx.newService.product_id]
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _withDirectives(_createElementVNode("input", {
                  class: "custom-input",
                  type: "text",
                  required: "",
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.tempdes) = $event)),
                  maxlength: "150",
                  placeholder: "Dirección",
                  style: {"padding-left":"0.5rem"}
                }, null, 512), [
                  [_vModelText, _ctx.tempdes]
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _withDirectives(_createElementVNode("input", {
                  class: "custom-input",
                  type: "text",
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.newService.location.site_name) = $event)),
                  maxlength: "20",
                  placeholder: "Referencia",
                  style: {"padding-left":"0.5rem"}
                }, null, 512), [
                  [_vModelText, _ctx.newService.location.site_name]
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.newService.customer_id) = $event)),
                  required: ""
                }, [
                  _cache[17] || (_cache[17] = _createElementVNode("option", { value: null }, "Seleccione un Suscriptor", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suscriptores, (customer) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: 'suscriptor-'+customer.subscriberId,
                      value: customer.subscriberId
                    }, _toDisplayString(customer.subscriberName), 9, _hoisted_21))
                  }), 128))
                ], 512), [
                  [_vModelSelect, _ctx.newService.customer_id]
                ])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("button", {
                  disabled: _ctx.isLoading,
                  type: "submit",
                  id: "submit",
                  class: "btn-submit-address",
                  value: ""
                }, [
                  (_ctx.isLoading)
                    ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                        key: 0,
                        style: {"width":"25px","height":"25px"},
                        strokeWidth: "8",
                        fill: "transparent",
                        animationDuration: ".5s",
                        "aria-label": "Loading"
                      }))
                    : (_openBlock(), _createElementBlock("strong", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t('message.planta.f8')), 1),
                          _cache[18] || (_cache[18] = _createElementVNode("div", null, [
                            _createElementVNode("img", {
                              src: _imports_1,
                              alt: "img-vector"
                            })
                          ], -1))
                        ])
                      ]))
                ], 8, _hoisted_23)
              ])
            ], 32)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}