import request from '@/utils/grafana-graphics'
const obj = {
  
  // getMonitoringUrlPrincipal(values: any) {
  //   return request({
  //     url: '/create-snapshot/graphics/monitoreoall',
  //     method: 'post',
  //     data: values
  //   })
  // },

  // getMonitoringUrlPrincipalTerminal(values: any) {
  //   return request({
  //     url: '/create-snapshot/graphics/monitoreoperterminal',
  //     method: 'post',
  //     data: values
  //   })
  // },

  getMonitoringUrlPrincipal(values: any) {
    return request({
      url: '/create-snapshot/graphics/viasat-monitoreo',
      method: 'post',
      data: values
    })
  },

  getMonitoringViasat(values: any) {
    return request({
      url: '/create-snapshot/graphics/viasat',
      method: 'post',
      data: values
    })
  },

  getMonitoringUrlPT(values: any) {
    return request({
      url: '/create-snapshot/graphics/viasatPT',
      method: 'post',
      data: values
    })
  },



}
export default obj