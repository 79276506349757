import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  class: "login-container",
  id: "login-container"
}
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { for: "username" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { for: "password" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.inizializa()), ["prevent"])),
      id: "world-map",
      class: "world"
    }, _cache[4] || (_cache[4] = [
      _createElementVNode("div", {
        id: "mapa",
        class: "mapa"
      }, null, -1)
    ])),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('message.auth.login')), 1),
      (_ctx.modeForm === 'login')
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.login(_ctx.user)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('message.auth.f1')), 1),
              _withDirectives(_createElementVNode("input", {
                required: "",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.username) = $event)),
                class: "input-login",
                type: "text",
                id: "username",
                placeholder: "Ingresa tu usuario"
              }, null, 512), [
                [_vModelText, _ctx.user.username]
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('message.auth.f2')), 1),
              _withDirectives(_createElementVNode("input", {
                required: "",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.password) = $event)),
                class: "input-login",
                type: "password",
                id: "password",
                placeholder: "Ingresa tu contraseña"
              }, null, 512), [
                [_vModelText, _ctx.user.password]
              ])
            ]),
            _createElementVNode("button", {
              disabled: _ctx.isLoading,
              type: "submit",
              id: "submit",
              class: "btn-submit",
              value: ""
            }, [
              (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                    key: 0,
                    style: {"width":"25px","height":"25px"},
                    strokeWidth: "8",
                    fill: "transparent",
                    animationDuration: ".5s",
                    "aria-label": "Loading"
                  }))
                : (_openBlock(), _createElementBlock("strong", _hoisted_8, _toDisplayString(_ctx.$t('message.auth.f3')), 1))
            ], 8, _hoisted_7)
          ], 32))
        : _createCommentVNode("", true)
    ])
  ]))
}