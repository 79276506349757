import application from "@/apis/aplication/aplication_flask";
export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    CREATE_SUBSCRIBER: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        application
          .CreateSubscriber(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },

    SERVICES_PLANS_TOKEN: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        application
          .ServicesPlansToken()
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },

    SUSCRIPTORS_ALL: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        application
          .SuscriptorsAll()
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
  },
};
