import { createRouter, createWebHashHistory,createWebHistory, RouteRecordRaw } from 'vue-router'
import Navbar from '../components/layout/navbar.vue';
import VideoBackground from '../components/layout/video.vue';
import Landing from '../views/landing/index.vue'
import Home from '../views/home/index.vue'
import Planta from '../views/planta/index.vue'
import Perfil from '../views/perfil/index.vue'
import store from '@/store';
import {
  getToken
} from '@/utils/auth'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Landing',
    meta: { Auth: false, title: 'SES' },
    components: { default: Landing, header: Navbar, background: VideoBackground},
  },
  {
    path: '/home',
    name: 'Home',
    meta: { Auth: true, title: 'SES | home' },
    components: { default: Home, header: Navbar, background: VideoBackground},
    
  },
  {
    path: '/planta',
    name: 'Planta',
    meta: { Auth: true, title: 'SES | Planta' },
    components: { default: Planta, header: Navbar}, 
  },
  {
    path: '/perfil',
    name: 'Perfil',
    meta: { Auth: true, title: 'SES | Perfil' },
    components: { default: Perfil, header: Navbar}, 
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.name !== 'Landing' && !store.getters.islogin) {
    if (getToken()) {
        next()
    }else{
        next({ name: 'Landing' })
    }
  } else next()
})
export default router
