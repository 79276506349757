<template>
    <div :style="'height:'+height+'px; width:'+width+'px'">
      <l-map ref="map" v-model:zoom="zoom" :center="[latitud, longitud]">
        <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          layer-type="base"
          name="OpenStreetMap"
        ></l-tile-layer>
        <l-circle-marker :lat-lng="[latitud, longitud]" :radius="50" />
      </l-map>
    </div>
  </template>
  
  <script>
  import "leaflet/dist/leaflet.css";
  import { LMap, LTileLayer, LCircleMarker} from "@vue-leaflet/vue-leaflet";
  import { mapState } from "vuex";
  
  export default {
    components: {
      LMap,
      LTileLayer,
      LCircleMarker
    },
    props: {
        height: {
            type: Number,
            default:0 
        },
        width: {
            type: Number,
            default:0 
        },
        latitud:{
          type:String
        },
        longitud:{
          type:String
        }
    },
    computed: {
        ...mapState({
            coordenate: (state) => state.AUTH.coordenateData,
        }),
    },
    data() {
      return {
        zoom: 17,
      };
    },
  };
  </script>