import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-content" }
const _hoisted_2 = { class: "overlay_2" }
const _hoisted_3 = { class: "conten" }
const _hoisted_4 = { class: "service" }
const _hoisted_5 = { href: "#" }
const _hoisted_6 = { class: "overlay_1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_newPlant = _resolveComponent("newPlant")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.permissions.some((evt) => evt.id === 119))
            ? (_openBlock(), _createBlock(_component_RouterLink, {
                key: 0,
                to: "/planta"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('message.home.btn1')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: "service",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.grafanaUrl()))
        }, [
          _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t('message.home.btn3')), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.showPopup)
        ? (_openBlock(), _createBlock(_component_newPlant, {
            key: 0,
            style: {"z-index":"5"},
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closePopup()))
          }))
        : _createCommentVNode("", true),
      _cache[2] || (_cache[2] = _createElementVNode("div", {
        style: {"z-index":"1"},
        class: "mapa"
      }, null, -1))
    ])
  ]))
}