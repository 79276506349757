<template>
    <div class="popup">
        <div class="popup-content">
            <slot></slot>
        </div>
    </div>
</template>
  
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PopupDrop',
});
</script>
  
<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(8, 8, 8, 0.933);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 40px; /* Ajusta el padding según tus necesidades para agregar más espacio */
  width: 60%;
  max-width: 780px;
  max-height: 90%;
  background-image: url('../../assets/Rectangle\ 40\ popup.png'); /* Ruta de la imagen de fondo */
  background-size: cover;
  opacity: 1; /* Opacidad de la imagen de fondo */
  border-radius: 10px;
  /* overflow-y: scroll;
  scrollbar-width: thin; 
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);  */

}

/* .popup-content::-webkit-scrollbar {
  width: 8px;
}

.popup-content::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.5);
}

.popup-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
} */

</style>
  