import request from "@/utils/aplication";
import requestViasat from "@/utils/viasat";
const obj = {
  getDataConfig(values: any) {
    return request({
      url: '/application',
      method: 'post',
      data: values
    })
  },
  functionConfig(values: any) {
    return request({
      url: '/function',
      method: 'post',
      data: values
    })
  },
  getUserInfo(values: any) {
    return request({
      url: "/orchestatorv3/userData",
      method: "post",
      data: values,
    });
  },
  getTerminalsInfo(values: any) {
    return request({
      url: "/orchestatorv3/dataList",
      method: "post",
      data: values,
    });
  },
  verifyAddress(values: any) {
    return requestViasat({
      url: `/get_location?latitude=${values.latitude}&longitude=${values.longitude}`,
      method: "get",
    });
  },
  getPlansByLocation(values: any) {
    return requestViasat({
      url: "/get_product",
      method: "post",
      data: values,
    });
  },
  getSuscribers(values: any) {
    return request({
      url: "/orchestatorv3/dataList",
      method: "post",
      data: values,
    });
  },
  createService(values: any) {
    return requestViasat({
      url: "/create",
      method: "post",
      data: values,
    });
  },
};
export default obj;
