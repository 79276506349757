import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/eliminar-50.png'


const _hoisted_1 = { class: "header-content2" }
const _hoisted_2 = { class: "close-style" }
const _hoisted_3 = { style: {"text-align":"center","margin-top":"1rem","margin-bottom":"3rem","color":"white","font-weight":"100"} }
const _hoisted_4 = { class: "group" }
const _hoisted_5 = { style: {"color":"white","line-height":"25px"} }
const _hoisted_6 = { class: "texto-alerta" }
const _hoisted_7 = { style: {"color":"rgb(253, 40, 68)","line-height":"23px","text-align":"justify","font-size":"1.4rem"} }
const _hoisted_8 = { class: "reason-input" }
const _hoisted_9 = {
  for: "reason",
  style: {"color":"white"}
}
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = {
  class: "btn-content2",
  style: {"display":"flex","justify-content":"space-evenly"}
}
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_PopupsuspenderSuscri = _resolveComponent("PopupsuspenderSuscri")!

  return (_openBlock(), _createBlock(_component_PopupsuspenderSuscri, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
            style: {"cursor":"pointer","margin-top":"-1rem"},
            width: "25",
            height: "25",
            src: _imports_0,
            alt: "delete-sign--v1"
          })
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("message.planta.f32")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", _hoisted_5, " SiteID: " + _toDisplayString(_ctx.terminalSelected.subscriptions_sai), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("message.planta.f35")), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("message.planta.f39")) + ":", 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "reason",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.suspendReason) = $event)),
          placeholder: _ctx.messageTraduct,
          style: {"width":"100%","padding":"0.5rem","margin-top":"0.5rem"}
        }, null, 8, _hoisted_10), [
          [_vModelText, _ctx.suspendReason]
        ])
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("button", {
          disabled: _ctx.isLoading,
          class: "btn-baja",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.suspendSubscription && _ctx.suspendSubscription(...args))),
          type: "submit",
          id: "btn-cerrar-popup2"
        }, [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                key: 0,
                style: {"width":"25px","height":"25px"},
                strokeWidth: "8",
                fill: "transparent",
                animationDuration: ".5s",
                "aria-label": "Loading"
              }))
            : (_openBlock(), _createElementBlock("strong", _hoisted_13, _toDisplayString(_ctx.$t("message.planta.f37")), 1))
        ], 8, _hoisted_12),
        _createElementVNode("button", {
          class: "btn-baja",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
          type: "button"
        }, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f38")), 1)
        ])
      ])
    ]),
    _: 1
  }))
}