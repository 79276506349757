<template>
  <div class="home-content">
    <!-- <Planeta></Planeta> -->

    <div class="overlay_2">
      <div class="conten">
        <div class="service">
          <!-- <RouterLink to="/planta">{{ $t('message.home.btn1')}}</RouterLink> -->
          <RouterLink v-if="permissions.some((evt: any) => evt.id === 119)" to="/planta">{{ $t('message.home.btn1') }}
          </RouterLink>
        </div>
        <!-- <div class="service" id="btn-abrir-popup" @click="showPopup = true">
          <a>Nuevo Servicio</a>
        </div> -->
        <!-- <div>
          <newPlant v-if="showPopup" @close="closePopup()"></newPlant>
        </div> -->

        <!-- <div> -->
        <!-- <button class="service-btn" id="btn-abrir-popup" @click="showPopup = true">
          <span >{{ $t('message.home.btn2')}}</span>
        </button > -->
        <!-- </div> -->

        <div class="service" @click="grafanaUrl()">
          <a href="#">{{ $t('message.home.btn3') }}</a>
        </div>
        <!-- <div class="service"><a href="/">Búsqueda</a></div> -->
        <!-- <div class="service">
          <RouterLink to="#">Consultar Cobertura</RouterLink>
        </div> -->
      </div>
    </div>

    <div class="overlay_1">
      <newPlant style="z-index: 5;" v-if="showPopup" @close="closePopup()"></newPlant>
      <div style="z-index: 1;" class="mapa"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import newPlant from "../planta/newService.vue";
import store from "../../store/index";
// import Planeta from "@/components/planetv2/planeta2.vue";
import { mapState, mapGetters } from 'vuex';

export default defineComponent({
  name: "Home",
  components: {
    newPlant,
  },
  computed: {
    ...mapState({
      coordenate: (state: any) => state.AUTH.coordenateData,
      user: (state: any) => state.AUTH.user,
      token: (state: any) => state.AUTH.token,
    }),
    ...mapGetters(["permissions"]),
  },
  data() {
    return {
      showPopup: false,
    };
  },
  methods: {
    closePopup() {
      this.showPopup = false;
    },
    // grafanaUrl() {
    //   var data = {
    //     principal: this.user.principalId,
    //   };

    //   console.log(data)
    //   store
    //     .dispatch("GETMONITORINGURLPRINCIPAL", data)
    //     .then((response) => {
    //       window.open(response.data.snapshotUrl, "_blank");
    //     })
    //     .catch((error) => {
    //       console.log("error", error);
    //     });
    // }

    grafanaUrl() {
      var data = {
        principal: this.user.principalId,
      };

      console.log(data);
      store
        .dispatch("GETMONITORINGURLPRINCIPAL", data)
        .then((response) => {
          let url = response.data.snapshotUrl;
          const hasQueryParams = url.includes("?");
          const separator = hasQueryParams ? "&" : "?";

          const urlWithParams = `${url}${separator}kiosk&principal=${data.principal}`;

          window.open(urlWithParams, "_blank");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

  },
});
</script>
<style scoped>
.home-content {
  height: calc(100vh - 107px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.overlay_2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 0;
  width: 100%;
  opacity: 0.85;
  flex-basis: 35%;
  margin-top: 30px;
}

.overlay_2 .conten {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 939px;
  margin-top: 98px;
}

.overlay_2 .conten .service {
  flex-basis: calc(33.3% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 15px;
}

.overlay_2 .conten .service-btn {
  flex-basis: calc(33.3% - 20px);
  display: flex;
  justify-content: center;
  cursor: pointer;
  background: rgba(0, 102, 255, 0.25);
  border: 2px solid rgba(0, 102, 255, 0.25);
  border-radius: 10px;
  height: 41px;
  width: 279px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;

  margin-top: 40px;
  margin-left: 15px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay_2 .conten .service-btn span {
  font-size: 18px;
  font-weight: bold;
}

.overlay_2 .conten .service a {
  margin-top: 40px;
  background: rgba(0, 102, 255, 0.25);
  border: 2px solid rgba(0, 102, 255, 0.25);
  border-radius: 10px;
  height: 41px;
  width: 279px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay_2 .conten .service a:hover {
  margin-top: 40px;
  background-color: #2001b9fe;
  border: 1px solid #bbbbbb;
  /* border: 2px solid #ffffff; */
  border-radius: 10px;
  font-weight: bold;
  height: 41px;
  width: 279px;
  color: #ffffff;
  /* color: rgba(0, 102, 255, 0.25); */
}


/* ///////////////////////// */
.overlay_1 {
  /* position: absolute; */

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 5;
  flex-basis: 65%;
  /* top: 0; */
  /* opacity: 0.85; */
}

/* -----------mapa animado------------- */

.mapa {
  z-index: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 520px;
  cursor: pointer;
  height: 520px;
  margin-top: 1rem;
  border-radius: 50%;
  background: url(@/assets/12MAP.jpeg);
  background-size: cover;
  box-shadow: inset 0 0 20px #000, inset -40px -10px 20px rgba(3, 32, 56, 0.7),
    inset 5px 5px 0 0 rgba(255, 255, 255, 0.2);

  animation: tierra-girando 44s infinite linear;
}

@keyframes tierra-girando {
  0% {
    background-position: 0px center;
  }

  25% {
    background-position: 900px center;
  }

  50% {
    background-position: 1800px center;
  }

  75% {
    background-position: 2700px center;
  }

  100% {
    background-position: 3600px center;
  }
}
</style>