<template>
  <Popup class="content">
    <template v-slot:header>
      <div class="header-content">
      <img @click="closePopup" style="cursor: pointer; margin-top: -1rem" width="25" height="25"
        src="@/assets/eliminar-50.png" alt="delete-sign--v1" />
      <h1 style="
        text-align: center;
    
       
        color: white;
        font-weight: 100;
      ">
        {{ $t('message.planta.f1')}}
      </h1>
    </div> 
    </template>
    <template v-slot:body>
      <div class="group-1">
        <form class="popup-form" @submit.prevent="address_selected ? continuar() : verificarAddress()">
        <div class="group">
          <input required maxlength="9" v-model="latitude" @change="limpia()" class="custom-input" type="text" placeholder="Latitud"
            style="padding-left: 0.5rem" />
        </div>
        <div class="group">
          <input maxlength="9" required  v-model="longitude"  @change="limpia()" class="custom-input" type="text" placeholder="Longitud"
            style="padding-left: 0.5rem" />
        </div>

  
          <v-select v-if="direcciones.length>0" v-model="address_selected" class="searche" label="postal_code" placeholder="Codigo Postal" :options="direcciones">
            
          </v-select>
 
    
        <div class="btn-content">
          <button :disabled="isLoading" type="submit" id="submit" class="btn-submit-address" value="">
          <ProgressSpinner style="width: 25px; height: 25px" strokeWidth="8" fill="transparent"
          animationDuration=".5s" aria-label="Loading" v-if="isLoading" />
          <strong v-else>
            {{ $t('message.planta.f3')}}
          </strong>
          
        </button> 
        
        </div>
        </form>
        <div style="margin: 20px 0px" v-if="validAddress">
          <Map :latitud="address_selected.latitude"  :longitud="address_selected.longitude" :height="200" :width="320"></Map>
        </div>    
      </div>
    <div v-if="validAddress" class="group-2">
      <form  class="popup-form" @submit.prevent="newServices(newService)">
        <div class="group">
        <input  class="custom-input" type="text" v-model="address_selected.country" disabled
          style="padding-left: 0.5rem" />
      </div>
      <div class="group">
        <input  class="custom-input" type="text" v-model="address_selected.country_code" disabled
          style="padding-left: 0.5rem" />
      </div>
      <div class="group">
        <input  class="custom-input" type="text" v-model="address_selected.municipality" disabled
          style="padding-left: 0.5rem" />
      </div>
      <div class="group">
        <input  class="custom-input" type="text" v-model="newService.sai" maxlength="20" required :placeholder="$t('message.planta.f5')" pattern="[A-Za-z0-9]+"
          style="padding-left: 0.5rem" />
      </div>
      <div class="group">
        <select v-model="newService.product_id" required>
                <option :value=null>Seleccione un Plan</option>
                <option v-for="plan in planes" :key="'plan-'+plan.id" :value="plan.id">{{ plan.name }}</option>
        </select>
      </div>
      <div class="group">
        <input  class="custom-input" type="text" required v-model="tempdes" maxlength="150"  placeholder="Dirección"
          style="padding-left: 0.5rem" />
      </div>
      <div class="group">
        <input  class="custom-input" type="text" v-model="newService.location.site_name" maxlength="20"  placeholder="Referencia"
          style="padding-left: 0.5rem" />
      </div>

      <div class="group">
        <select v-model="newService.customer_id" required>
                <option :value=null>Seleccione un Suscriptor</option>
                <option v-for="customer in suscriptores" :key="'suscriptor-'+customer.subscriberId" :value="customer.subscriberId">{{ customer.subscriberName }}</option>
        </select>
      </div>
  
        <div class="btn-content">
        <button :disabled="isLoading" type="submit" id="submit" class="btn-submit-address" value="">
          <ProgressSpinner style="width: 25px; height: 25px" strokeWidth="8" fill="transparent"
          animationDuration=".5s" aria-label="Loading" v-if="isLoading" />
          <strong v-else>
            <div style="
              display: flex;
              justify-content: space-evenly;
              width: 90%;
              margin: auto;
              align-items: center;
            ">
            
            <div>{{$t('message.planta.f8')}}</div>
            <div>
              <img src="@/assets/Vector-servicio.png" alt="img-vector" />
            </div>
          </div>
   
          </strong>
         
        </button> 

      </div>
   
      </form>
    </div>
    </template>
  </Popup>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Popup from "../../components/planta-conte/nuevoServicio.vue";
import store from "../../store";
import "@/assets/sass/StylesPlanta.scss";
import { mapState, mapGetters } from "vuex";
import Map from "../../components/map/index.vue";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import ProgressSpinner from 'primevue/progressspinner';
export default defineComponent({
  name: "newService",
  emits: ["close"],
  components: {
    Popup,
    Map,
    vSelect,
    ProgressSpinner
  },
  computed: {
    ...mapState({
      coordenate: (state: any) => state.AUTH.coordenateData,
      user: (state: any) => state.AUTH.user,
      token: (state: any) => state.AUTH.token,
      isLoading: (state: any) => state.AUTH.isLoadingInter,
    }),
    ...mapGetters(["permissions"]),

  },
  data() {
    return {
      latitude:"",
      longitude:"",
      direcciones:[],
      validAddress: false,
      process: false,
      tempdes:null,
      address_selected:null,
      newService: {
        sai: "",
        product_id: null,
        customer_id:null,
        location:{
          latitude: null, 
          longitude: null, 
          address_lines: "", 
          country_code: null, 
          municipality: null, 
          region: null, 
          site_name: null,
          region_code: null, 
          postal_code: null
        }
      },
      suscriptores: [],
      planes: [],
    };
    
  },
  mounted() {
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    newServices(values:any) {

      this.process = true;
      values.principal_id= 191;
      var tempRef=values.location.site_name;
      values.location=this.address_selected

    
      values.location.site_name=tempRef;
      if (values.product_id && values.customer_id) {
        if (this.tempdes) {
          values.location.address_lines=null;
          values.location.address_lines=[this.tempdes]
        }else{
          values.location.address_lines=[""]
        }
        store.dispatch("CREATE_SERVICE", values).then((response) => {
        this.process = false;
        store.dispatch("showToast", {
                 message: String('Creado Correctamente'),
                 type: "success",
                })
        this.closePopup();
           
            
      }).catch((error) => {
               this.process = false;
               store.dispatch("showToast", {
                 message: String(error.response.data.message[0]),
                 type: "error",
                })
      })
      
      }else{
        if (!values.product_id) {
          store.dispatch("showToast", {
                 message: String('Plan Requerido'),
                 type: "error",
                })
        }
        if (!values.customer_id) {
          store.dispatch("showToast", {
                 message: String('Suscriptor Requerido'),
                 type: "error",
                })
        }
        store.commit("SET_ISLOADING_INTER", false);
      }
     
     
      

    },
   limpia(){
    this.address_selected=null
    this.validAddress=false
    this.direcciones=[]
   },
    verificarAddress() {
        var data={
          latitude:this.latitude,
          longitude:this.longitude
        }
        store.dispatch("VERIFYADDRESS", data).then((response2) => {
                this.direcciones=response2.data.data.items;
                // this.getPlansByLocation({location: this.newService.location});
                // this.getSuscribers()
                // this.validAddress = true;
                console.log(response2)
        }).catch((error) => {
               this.validAddress = false;
               store.dispatch("showToast", {
                 message: String(error.response.data.message[0]),
                 type: "error",
                }
              )
        }
        );
    },
    continuar(){
      this.getPlansByLocation({location: this.address_selected});
      this.getSuscribers()
    },
    getPlansByLocation(data:any){
      store.dispatch("GET_PLANS_BY_LOCATIONS", data).then((response) => {
                this.planes=response.data.data
                this.validAddress = true;
      }).catch((error) => {
               this.validAddress = false;
               store.dispatch("showToast", {
                 message: String(error.response.data.message[0]),
                 type: "error",
                })
              })
    },
    getSuscribers(){
      var data ={
	      type: "data",
	      endpoint: 85
      }
      store.dispatch("GET_SUSCRIBERS", data).then((response) => {
                console.log(response)
                this.suscriptores=response.data.data
      }).catch((error) => {
               store.dispatch("showToast", {
                 message: String(error.response.data.message[0]),
                 type: "error",
                })
      })
    },
  },
});
</script>
<style lang="scss" scoped>

.group-1{
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .popup-form{
    width: 100%;
    .group{
      .custom-input {
          background-color: white;
          border: 1px solid #ccc;
          padding: 8px;
          border-radius: 4px;
          width:320px;
          cursor: pointer;
        }
       
      }
  }
}
.group-2{
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .popup-form{
    width: 100%;
    .group{
      .custom-input {
      background-color: white;
      border: 1px solid #ccc;
      padding: 8px;
      border-radius: 4px;
      width: 100%;
      cursor: pointer;
    }
    select{
      background-color: white;
      border-radius: 4px;
      height: 30px;
      width: 100%;
      width:320px;
    }
     
    }
  }
}



.btn-submit-address {
  height: 32px;
  width: 100%;
  border: none;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  background-color: #0066ff;
}

.btn-submit-mapa {
  height: 32px;
  width: 100%;
  border: none;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  background-color: #0066ff;
}





.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
}

  .searche{
    background-color: white;
    border-radius: 4px;
    margin-left: 20px;
    width: 100%;
    width:320px;
  }
</style>
