import request from "@/utils/aplication_flask";
const obj = {
  CreateSubscriber(values: any) {
    return request({
      url: "/create-subscriber",
      method: "post",
      data: values,
    });
  },

  ServicesPlansToken() {
    return request({
      url: "/services-plans-token",
      method: "get",
    });
  },

  SubscrtiberAll() {
    return request({
      url: "/subscriber-all",
      method: "get",
    });
  },

  UpdateSubscriber(subscriberId: string, values: any) {
    return request({
      url: `/update-subscriber/${subscriberId}`,
      method: "put",
      data: values,
    });
  },

  DeleteSubscriber(subscriberId: string) {
    return request({
      url: `/delete-subscriber/${subscriberId}`,
      method: "delete",
    });
  },

  ChangePlan(values: any) {
    return request({
      url: "/change-plan",
      method: "post",
      data: values,
    });
  },

  ChangeAddress(subscribers: string, addresses: string) {
    return request({
      url: "/change-address",
      method: "patch",
      params: {
        subscribers: subscribers,
        addresses: addresses,
      },
    });
  },

  GetSubscriberDetails(subscriberId: string) {
    return request({
      url: `/ot-subscriber/${subscriberId}`,
      method: "get",
    });
  },

  CreateCustomer(subscriberId: string, values: any) {
    return request({
      url: `/create-customer/${subscriberId}`,
      method: "post",
      data: values,
    });
  },

  ServicesToken(servicesId: string, data: any) {
    return request({
      url: `/services-token/${servicesId}`,
      method: "post",
      data: data,
    });
  },

  SuscriptorsAll() {
    return request({
      url: "/addresses-all",
      method: "get",
    });
  },

};
export default obj;
