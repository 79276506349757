import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "maxiem" }
const _hoisted_2 = { class: "perfil-content" }
const _hoisted_3 = { class: "tags-content" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "formulario-user-data"
}
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "footer-content" }
const _hoisted_9 = {
  key: 2,
  class: "formulario-user-data"
}
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { class: "footer-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.mode = 'perfil'), ["prevent"])),
          class: _normalizeClass(_ctx.mode === 'perfil' ? 'active' : 'tag')
        }, " Perfil ", 2),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.mode = 'seguridad'), ["prevent"])),
          class: _normalizeClass(_ctx.mode === 'seguridad' ? 'active' : 'tag')
        }, " Seguridad ", 2)
      ]),
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_4, "Bienvenido " + _toDisplayString(_ctx.user.fullname), 1))
        : _createCommentVNode("", true),
      (_ctx.mode === 'perfil')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("form", {
              onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_6, [
                _cache[10] || (_cache[10] = _createElementVNode("label", { for: "username" }, "Usuario", -1)),
                _withDirectives(_createElementVNode("input", {
                  required: "",
                  disabled: "",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.email) = $event)),
                  class: "input-login",
                  type: "text",
                  id: "username",
                  placeholder: "Ingresa tu usuario"
                }, null, 512), [
                  [_vModelText, _ctx.user.email]
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[11] || (_cache[11] = _createElementVNode("label", { for: "username" }, "Nombre Completo", -1)),
                _withDirectives(_createElementVNode("input", {
                  required: "",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.fullname) = $event)),
                  class: "input-login",
                  type: "text",
                  id: "username",
                  placeholder: "Ingresa tu nombre"
                }, null, 512), [
                  [_vModelText, _ctx.user.fullname]
                ])
              ])
            ], 32),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("button", {
                onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.updatedPerfil(_ctx.user)), ["prevent"])),
                class: "btn-submit"
              }, " Guardar ")
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("form", {
              onSubmit: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_10, [
                _cache[12] || (_cache[12] = _createElementVNode("label", { for: "password" }, "Contraseña", -1)),
                _withDirectives(_createElementVNode("input", {
                  required: "",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newpassword.password) = $event)),
                  class: "input-login",
                  type: "password",
                  id: "password",
                  placeholder: "Ingresa tu nueva contraseña"
                }, null, 512), [
                  [_vModelText, _ctx.newpassword.password]
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _cache[13] || (_cache[13] = _createElementVNode("label", { for: "password" }, "Confirma Contraseña", -1)),
                _withDirectives(_createElementVNode("input", {
                  required: "",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newpassword.confirm_password) = $event)),
                  class: "input-login",
                  type: "password",
                  id: "password",
                  placeholder: "confirma tu nueva contraseña"
                }, null, 512), [
                  [_vModelText, _ctx.newpassword.confirm_password]
                ])
              ])
            ], 32),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("button", {
                onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.updatedPassword(_ctx.newpassword)), ["prevent"])),
                class: "btn-submit"
              }, " Guardar ")
            ])
          ]))
    ])
  ]))
}