import request from "@/utils/viasat";
const obj = {
//   problemtype() {
//     return request({
//       url: '/incident-resources/listProblem',
//       method: "get",
//     });
//   },
  suspend(values: any) {
    return request({
      url: '/suspend',
      method: 'post',
      data: values
    })
  },

  baja(values: any) {
    return request({
      url: '/baja',
      method: 'post',
      data: values
    })
  },

  reactivar(values: any) {
    return request({
      url: '/reactivate',
      method: 'post',
      data: values
    })
  },


  



  


};
export default obj;