import request from "@/utils/request";
const obj = {
  login(values: any) {
    return request({
      url: "/login",
      method: "post",
      data: values,
    });
  },
  recover(values: any) {
    return request({
      url: "/recover_password",
      method: "post",
      data: values,
    });
  },
  register(values: any) {
    return request({
      url: "/register",
      method: "post",
      data: values,
    });
  },
  refresh(values: any) {
    return request({
      url: "/validateToken",
      method: "post",
      data: values,
    });
  },
  logout() {
    return request({
      url: "/logout",
      method: "post",
    });
  },
  update(id: string, values: any) {
    return request({
      url: "/update/" + id,
      method: "post",
      data: values,
    });
  },
  refresh_token(values: any) {
    return request({
      url: "/refresh",
      method: "post",
      data: values,
    });
  },
};
export default obj;