<template>
  <PopupbajaSuscri>
    <div class="header-content2">
      <div style="margin-left: 12rem;" class="close-style">

        <img @click="closePopup" style="cursor: pointer; margin-top: -1rem" width="25" height="25"
          src="@/assets/eliminar-50.png" alt="delete-sign--v1" />
      </div>

      <div>

        <h1 style="
          text-align: center;
          margin-top: 1rem;
          margin-bottom: 3rem;
          color: white;
          font-weight: 100;
        ">
          {{ $t("message.planta.f31") }}
        </h1>
      </div>
    </div>

    <div class="group">
      <h3 style="color: white; line-height: 25px">
        SiteID: {{ terminalSelected.subscriptions_sai }}
      </h3>
    </div>

    <div class="texto-alerta">
      <p style="color: rgb(253, 40, 68); line-height: 23px; text-align: justify;font-size: 1.4rem;">
        {{ $t("message.planta.f34") }}
      </p>
    </div>

    <div class="reason-input">
      <label for="reason" style="color: white;">Reason for suspension:</label>
      <input type="text" id="reason" v-model="terminateReason" :placeholder="messageTraduct"
        style="width: 100%; padding: 0.5rem; margin-top: 0.5rem;" />
    </div>

    <br>
    <br>

    <div class="btn-content2" style="display: flex; justify-content: space-evenly;">
      <button :disabled="isLoading" class="btn-baja" @click="terminateSubscription" type="submit" id="btn-cerrar-popup2">
          <ProgressSpinner style="width: 25px; height: 25px" strokeWidth="8" fill="transparent"
          animationDuration=".5s" aria-label="Loading" v-if="isLoading" />
          <strong v-else>
            {{ $t("message.planta.f37") }}
          </strong>
         
        </button>
      <button class="btn-baja" @click="closePopup" type="button">
        <strong>{{ $t("message.planta.f38") }}</strong>
      </button>
    </div>

  </PopupbajaSuscri>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// import Popup from "../../../components/planta-conte/Popup.vue";
import store from "../../../store";
import "@/assets/sass/StylesPlanta.scss";
import { mapState, mapGetters } from "vuex";
import PopupbajaSuscri from "@/components/planta-conte/PopupbajaSuscri.vue";
import ProgressSpinner from 'primevue/progressspinner';
export default defineComponent({
  name: "newService",
  props: ["terminalSelected"],
  emits: ["close"],
  components: {
    PopupbajaSuscri,
    ProgressSpinner 
  },
  computed: {
    ...mapState({
      user: (state: any) => state.AUTH.user,
      token: (state: any) => state.AUTH.token,
      isLoading: (state: any) => state.AUTH.isLoadingInter,
    }),
    ...mapGetters(["permissions"]),
  },
  data() {
    return {
      terminateReason: "",
      messageTraduct: ""
    };
  },
  mounted() { },
  methods: {
    closePopup() {

      this.$emit("close");
    },


    terminateSubscription() {
      if (!this.terminateReason) {
        store.dispatch("showToast", {
          message: "Please provide a reason for termination",
          type: "error",
        });
        return;
      }

      const payload = {
        subscription_id: this.terminalSelected.subscription_id, // Verificar que el ID sea correcto
        reason: this.terminateReason,
      };

      console.log("Payload:", payload);

      store
        .dispatch("BAJA", payload)
        .then((response) => {
          store.dispatch("showToast", {
            message: "Suscripción dada de baja con éxito",
            type: "success",
          });
          this.closePopup(); // Cierra el popup después de dar de baja exitosamente
        })
        .catch((error) => {
          console.log("Error al dar de baja la suscripción:", error);
          store.dispatch("showToast", {
            message: "Error al dar de baja la suscripción",
            type: "error",
          });
        });
    },



  },
});
</script>
<style lang="scss">
.dropdown-content {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
  margin-top: 0px;
}

.dropdown-content:last-of-type {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
  margin-top: -225px;
  margin-left: 45px;
}

.dropdown-content:hover {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
}

.dropdown-content a:hover {
  background-color: #0079ff;
}

.btn-submit-address {
  height: 32px;
  width: 220px;
  border: none;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  background-color: #0066ff;
}

.btn-submit-mapa {
  height: 32px;
  width: 220px;
  border: none;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  background-color: #0066ff;
}

.custom-select .vs__dropdown-toggle {
  background-color: white;
}

.custom-input {
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  width: 95%;
  cursor: pointer;
}

.header-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.poppup-body p {
  color: #ffffff;
}

.close-style {
  display: flex;
  justify-content: end;
}

.btn-content2 button {
  height: 40px;
  cursor: pointer;
  width: 9rem;
  border-radius: 9px;
  background-color: #007bff;
  color: #ffffff;
}
</style>
